import React, { useEffect, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { navigate } from 'gatsby'

import Layout from '../../templates/Layout'
import * as S from '../../styles/login.styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ErrorMessage, InfoMessage } from '../../components/shared'
import { resetPassword } from '../../services/fetch/user'
import {
  defaultErrorMsg,
  getQueryParams,
} from '../../components/forms/util/helpers'
import { LOGIN_ROUTE } from '../../constants/routes'
import { withErrorHandler } from '../../components/errorHandler'

const ResetPassword: React.FC = () => {
  const [token, setToken] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [pending, setPending] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [done, setDone] = useState<boolean>(false)

  useEffect(() => {
    getTokenFromQueryParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTokenFromQueryParams = () => {
    const queryParams = getQueryParams()
    if (queryParams) {
      const paramToken = queryParams.get('token')
      if (paramToken) {
        setToken(paramToken)
      }
    }
  }

  // return true if passwords match
  const validatePasswordsMatch = (): boolean => {
    if (newPassword === '' || confirmPassword === '') {
      return false
    }

    return newPassword === confirmPassword
  }

  const { color, opacity } = useSpring({
    color: newPassword === '' || confirmPassword === '' ? '#000000' : 'white',
    opacity: newPassword === '' || confirmPassword === '' ? 0.2 : 1,
  })

  const { color: textColor } = useSpring({
    color: newPassword === '' || confirmPassword === '' ? 'white' : '#000000',
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setPending(true)
    setError('')

    if (validatePasswordsMatch()) {
      resetPassword({ password: newPassword }, token)
        .then(() => {
          setPending(false)
          setDone(true)
        })
        .catch(err => {
          setConfirmPassword('')
          setPending(false)
          setError((err && err?.message) || defaultErrorMsg)
        })
    } else {
      setConfirmPassword('')
      setError('Passwords need to match')
      setPending(false)
    }
  }

  return (
    <Layout>
      <S.Container>
        {done ? (
          <>
            <InfoMessage message="Password reset successfully. You can now log in with your new password." />
            <S.SecondaryText
              onClick={() => navigate(LOGIN_ROUTE)}
              style={{
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              Go To Login
            </S.SecondaryText>
          </>
        ) : (
          <>
            <S.SecondaryText
              style={{ fontSize: 36, marginBottom: 24, textAlign: 'center' }}
            >
              Reset Password
            </S.SecondaryText>
            <ErrorMessage message={error} style={{ width: 350 }} />
            <S.FormWrap onSubmit={handleSubmit}>
              <S.FormInputDiv>
                <S.FormInput
                  type="password"
                  name="newPassword"
                  placeholder="New Password"
                  required
                  onChange={e => setNewPassword(e.target.value)}
                />
              </S.FormInputDiv>
              <S.FormInputDiv>
                <S.FormInput
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  required
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </S.FormInputDiv>
              <S.SubmitButton
                as={animated.button}
                style={{
                  backgroundColor: color,
                }}
                type="submit"
                disabled={newPassword === '' || confirmPassword === ''}
              >
                <S.SubmitText
                  as={animated.div}
                  style={{ color: textColor, opacity }}
                >
                  {pending ? 'Resetting Password...' : 'Reset Password'}
                </S.SubmitText>
                {pending && (
                  <S.SpinnerWrap>
                    <CircularProgress size={18} color="secondary" />
                  </S.SpinnerWrap>
                )}
              </S.SubmitButton>
            </S.FormWrap>
          </>
        )}
      </S.Container>
    </Layout>
  )
}

export default withErrorHandler(ResetPassword)
