import Stripe from 'stripe'
import {
  API_ADD_TO_EMAIL_LIST,
  API_CHANGE_PASSWORD,
  API_UPDATE_USER_PAYMENT,
  API_FORGOT_PASSWORD,
  API_RESET_PASSWORD,
} from '../apiRoutes'
import request from '../request'

export interface ChangePasswordRequest {
  oldPassword: string
  newPassword: string
}

export interface ChangePasswordResponse {
  status: 'Success' | 'Error'
  msg: string
}

export const changePassword = async (
  body: ChangePasswordRequest,
): Promise<ChangePasswordResponse> =>
  request(API_CHANGE_PASSWORD, body).then(res => res as ChangePasswordResponse)

export interface UpdateUserPaymentRequest {
  paymentMethodId: string | undefined
}

export const updateUserPayment = async (
  body: UpdateUserPaymentRequest,
): Promise<Stripe.Customer> =>
  request(API_UPDATE_USER_PAYMENT, body).then(res => res as Stripe.Customer)

export enum SubscriptionType {
  monthly = 'monthly',
  annual = 'annual',
}

export interface EmailListRequest {
  email: string
}

export interface EmailListResponse {
  status: string
  msg: string
}

export const addToEmailList = async (
  body: EmailListRequest,
): Promise<EmailListResponse> =>
  request(API_ADD_TO_EMAIL_LIST, body, {}, true).then(
    res => res as EmailListResponse,
  )

export interface ForgotPasswordRequest {
  email: string
}

export interface ForgotPasswordResponse {
  status: string
  msg: string
}

export const forgotPasswordInitiate = async (
  body: ForgotPasswordRequest,
): Promise<ForgotPasswordResponse> =>
  request(API_FORGOT_PASSWORD, body, {}, true).then(
    res => res as ForgotPasswordResponse,
  )

export interface ResetPasswordRequest {
  password: string
}

export interface ResetPasswordResponse {
  status: string
  msg: string
}

export const resetPassword = async (
  body: ResetPasswordRequest,
  token: string,
): Promise<ResetPasswordResponse> =>
  request(API_RESET_PASSWORD, body, { token }).then(
    res => res as ResetPasswordResponse,
  )
